import { useEffect, useState } from "react";
import ManageModal from "./ManageModal";
import { createChannelData, deleteChannelData, updateChannelData } from "../services/ChatService";

function ManageChannelsModal({ setOpen, isOpen, channels, fetchChannels }) {
    const [selectedChannel, setSelectedChannel] = useState(null);
    const [isNewChannel, setIsNewChannel] = useState(false);
    const [formValues, setFormValues] = useState({
        name: '',
        description: '',
    });
    const token = localStorage.getItem('token');

    useEffect(() => {
        setSelectedChannel(null);
        setIsNewChannel(false);
        setFormValues({
            name: '',
            description: '',
        });
    }, [isOpen]);

    const handleChannelClick = (channel) => {
        setIsNewChannel(false);
        setSelectedChannel(channel);
        setFormValues({
            name: channel.name,
            description: channel.description,

        });
    };

    const handleAddNewChannel = () => {
        setIsNewChannel(true);
        setSelectedChannel(null);
        setFormValues({
            name: '',
            description: '',
        });
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const updateChannel = async () => {
        updateChannelData(token, selectedChannel, formValues).then(() => {
            fetchChannels();
            setOpen(false);
        });
    };

    const createChannel = async () => {
        createChannelData(token, formValues).then(() => {
            fetchChannels();
            setOpen(false);
        });
    };

    const deleteChannel = async () => {
        deleteChannelData(token, selectedChannel).then(() => {
            fetchChannels();
            setOpen(false);
        });
    };


    const handleSave = () => {
        if (isNewChannel) {
            createChannel();
        } else {
            updateChannel();
        }
    };

    return (
        <ManageModal setOpen={setOpen} isOpen={isOpen}>
            <div className="flex h-[400px]">
                {/* Channel Selector */}
                <div className="flex flex-col bg-gray-800">
                    <div className="space-y-2 p-2 overflow-y-auto">
                        {channels.map((channel) => (
                            <button
                                key={channel.id}
                                onClick={() => handleChannelClick(channel)}
                                className="cursor-pointer w-full text-left px-3 py-2 text-sm font-medium text-gray-300 bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
                            >
                                <div className="font-semibold">
                                    {channel.name}
                                </div>
                            </button>
                        ))}
                    </div>
                    <div className="px-2 mt-auto mb-2">
                        <button
                            onClick={handleAddNewChannel}
                            className="w-full cursor-pointer text-center p-2 text-sm font-medium text-gray-300 bg-gray-900 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
                        >
                            Add new channel
                        </button>
                    </div>
                </div>

                {/* Channel Details Form */}
                <div className="flex flex-col w-[200px] sm:w-[400px] p-2 bg-gray-900">
                    {(selectedChannel || isNewChannel) && (
                        <>
                            <form className="space-y-2">
                                <div>
                                    <label className="block text-gray-300">Name</label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={formValues.name}
                                        onChange={handleChange}
                                        className="w-full p-2 text-gray-900 rounded-md"
                                    />
                                </div>
                                <div className="flex items-center space-x-2">
                                    <label className="block text-gray-300">Description</label>
                                    <input
                                        type="text"
                                        name="description"
                                        value={formValues.description}
                                        onChange={handleChange}
                                        className="w-full p-2 text-gray-900 rounded-md"
                                    />
                                </div>
                            </form>
                            <div className="flex mx-auto h-full items-center justify-center space-x-16">
                                {!isNewChannel && (
                                    <button
                                        onClick={deleteChannel}
                                        className="flex justify-center items-center cursor-pointer text-left mt-auto h-[36px] w-[64px] text-sm text-sm font-bold text-gray-300 bg-[#513737] rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
                                    >
                                        Delete
                                    </button>
                                )}
                                <button
                                    onClick={handleSave}
                                    className="flex justify-center items-center cursor-pointer text-left mt-auto h-[36px] w-[64px] text-sm text-sm font-bold text-gray-300 bg-[#375137] rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
                                >
                                    Save
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </ManageModal>
    );
}

export default ManageChannelsModal;
