import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useRef, useEffect, useState } from "react";
import SeenModal from "./SeenModal";
import { parseTime } from "../utils/TimeUtil";
import Avatar from "./Avatar";
import { markMessagesAsReadData } from "../services/ChatService";

function isMediaAnImage(fileName) {
    return fileName.match(/\.(jpeg|jpg|gif|png)$/) != null;
}

function MessageList({ messages, users, mediaList, deleteMessage, editMessage, permissions, currentUser }) {
    const messageListRef = useRef(null);
    const [editingMessageId, setEditingMessageId] = useState(null);
    const [editedContent, setEditedContent] = useState("");
    const [visibleMessageIds, setVisibleMessageIds] = useState(new Set());

    const editingMessageRef = useRef(null);

    const [seenModalOpen, setSeenModalOpen] = useState(false);
    const [selectedMessageId, setSelectedMessageId] = useState(null);

    const token = localStorage.getItem("token");

    useEffect(() => {
        const messageList = messageListRef.current;
        messageList.scrollTop = messageList.scrollHeight;
    }, [messages, mediaList]);

    useEffect(() => {
        if (editingMessageRef.current) {
            editingMessageRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
        }
    }, [editingMessageId]);

    useEffect(() => {
        const messageList = messageListRef.current;

        const handleScroll = () => {
            const visibleIds = new Set([...visibleMessageIds]);

            messages.forEach((message) => {
                const messageElement = document.getElementById(`message-${message.id}`);
                if (messageElement) {
                    const rect = messageElement.getBoundingClientRect();
                    const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;

                    if (isVisible && !visibleIds.has(message.id)) {
                        visibleIds.add(message.id);
                    }
                }
            });

            if (visibleIds.size > visibleMessageIds.size) {
                const newVisibleIds = [...visibleIds].filter(id => !visibleMessageIds.has(id));
                setVisibleMessageIds(visibleIds);
                markMessagesAsRead(newVisibleIds);
            }
        };

        handleScroll();

        messageList.addEventListener('scroll', handleScroll);

        return () => {
            messageList.removeEventListener('scroll', handleScroll);
        };
    }, [messages, visibleMessageIds]);

    const handleEditClick = (message) => {
        setEditingMessageId(message.id);
        setEditedContent(message.content);
    };

    const handleCancelEdit = () => {
        setEditingMessageId(null);
        setEditedContent("");
    };

    const handleSaveEdit = (messageId) => {
        editMessage(messageId, editedContent);
        setEditingMessageId(null);
        setEditedContent("");
    };

    const handleSeenClick = (messageId) => {
        setSelectedMessageId(messageId);
        setSeenModalOpen(true);
    }

    const markMessagesAsRead = async (messageIds) => {
        markMessagesAsReadData(token, messageIds);
    };

    return (
        <div ref={messageListRef} className="flex-1 overflow-y-auto bg-gray-900">
            <div className="w-100%">
                {messages.slice(0).reverse().map((message) => (
                    <div
                        key={message.id}
                        id={`message-${message.id}`}
                        className={`relative flex items-start space-x-3 p-2 hover:bg-[#161e2e] focus-within:bg-[#151d2e] group ${message.user_id === currentUser?.id ? "justify-end" : "justify-start"}`}
                        ref={editingMessageId === message.id ? editingMessageRef : null}
                    >
                        {message.user_id !== currentUser?.id && (
                            <Avatar className="w-10 h-10" profileImage={users.find((user) => user.id === message.user_id)?.profile_image} />
                        )}
                        <div>
                            <div className={`text-sm font-semibold text-white space-x-1.5 ${message.user_id === currentUser?.id ? "text-right" : ""}`}>
                                {message.user_id === currentUser?.id ? (
                                    <>
                                        <span className="text-xs text-gray-500">{parseTime(message.date_created)}</span>
                                        <span>{users.find((user) => user.id === message.user_id)?.display_name}</span>
                                    </>
                                ) : (
                                    <>
                                        <span>{users.find((user) => user.id === message.user_id)?.display_name}</span>
                                        <span className="text-xs text-gray-500">{parseTime(message.date_created)}</span>
                                    </>
                                )}
                            </div>
                            {editingMessageId === message.id ? (
                                <div className="mt-1 text-sm text-gray-300">
                                    <input
                                        type="text"
                                        value={editedContent}
                                        onChange={(e) => setEditedContent(e.target.value)}
                                        className="w-full p-1 text-gray-800 bg-gray-200 rounded"
                                    />
                                    <div className="mt-2 space-x-2">
                                        <button
                                            onClick={() => handleSaveEdit(message.id)}
                                            className="px-3 py-1 text-white bg-blue-500 rounded hover:bg-blue-600"
                                        >
                                            Save
                                        </button>
                                        <button
                                            onClick={handleCancelEdit}
                                            className="px-3 py-1 text-white bg-gray-500 rounded hover:bg-gray-600"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className={`mt-1 text-sm text-gray-300 break-words whitespace-normal max-w-[100%] ${message.user_id === currentUser?.id ? "text-right" : ""}`}>
                                    {message.content}
                                </div>
                            )}
                            {mediaList
                                .filter((media) => media.message_id === message.id)
                                .map((media) => (
                                    <div key={media.id}>
                                        {isMediaAnImage(media.file_name) ? (
                                            <img
                                                src={media.url}
                                                alt={media.file_name}
                                                className="max-w-[320px] h-auto max-h-[900px] mt-2 rounded"
                                            />
                                        ) : (
                                            <a
                                                href={media.url}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="text-sm text-blue-400 underline"
                                            >
                                                {media.file_name}
                                            </a>
                                        )}
                                    </div>
                                ))}
                        </div>
                        {(message.user_id === currentUser?.id || permissions.manage_channels) && (
                            <div className={`absolute space-x-2 text-white text-sm ${message.user_id === currentUser?.id ? "left-1.5" : "right-4"} opacity-0 group-hover:opacity-100 group-focus-within:opacity-100`}>
                                <button onClick={() => handleSeenClick(message.id)}>
                                    <Icon icon="fa:eye" className="w-5 h-5" />
                                </button>
                                {message.user_id === currentUser.id && (
                                    <button onClick={() => handleEditClick(message)}>
                                        <Icon icon="material-symbols:edit" className="w-5 h-5" />
                                    </button>
                                )}
                                {(message.user_id === currentUser.id || permissions.manage_channels) && (
                                    <button onClick={() => deleteMessage(message.id)}>
                                        <Icon icon="material-symbols:delete" className="w-5 h-5" />
                                    </button>
                                )}
                            </div>
                        )}
                        {message.user_id === currentUser?.id && (
                            <Avatar className="w-10 h-10" profileImage={users.find((user) => user.id === message.user_id)?.profile_image} />
                        )}
                    </div>

                ))}
            </div>
            <SeenModal isOpen={seenModalOpen} setOpen={setSeenModalOpen} messageId={selectedMessageId} users={users} />
        </div>
    );
}

export default MessageList;
