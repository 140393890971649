function ManageModal({ setOpen, isOpen, children }) {
    return (
        isOpen &&
        <div
            className="absolute top-0 left-0 z-[1000] w-screen h-screen bg-black/50 backdrop-blur-sm flex items-center justify-center"
            onClick={() => setOpen(false)}
        >
            <div
                onClick={(e) => e.stopPropagation()} // Prevents the modal content from closing when clicked
            >
                {children}
            </div>
        </div>
    );
}

export default ManageModal;
