import { useState, useEffect } from "react";
import ManageModal from "./ManageModal";
import { updateDisplayNameData, uploadProfileImageData } from "../services/ChatService";

function ManageCurrentUserModal({ setOpen, isOpen, currentUser, fetchCurrentUser }) {
    const [displayName, setDisplayName] = useState(currentUser?.display_name || "");
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (currentUser) {
            setDisplayName(currentUser.display_name || "");
        }
    }, [currentUser]);

    const updateName = async () => {
        updateDisplayNameData(token, currentUser, displayName).then(() => {
            // fetchCurrentUser();
            setOpen(false);
        });
    };

    const uploadProfileImage = async (file) => {
        uploadProfileImageData(token, file).then(() => {
            setOpen(false);
        });
    };

    const handleProfileImageChange = (event) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            if (file.type === 'image/png') {
                uploadProfileImage(file);
            } else {
                alert('Please select a PNG image.');
            }
        }
    };

    const handleSave = async () => {
        await updateName();
    };

    return (
        <ManageModal setOpen={setOpen} isOpen={isOpen}>
            <div className="flex flex-col items-center space-y-4 p-6 bg-gray-800 text-white rounded-lg">
                <div className="relative w-32 h-32">
                    {currentUser?.profile_image ? (
                        <img
                            src={currentUser.profile_image}
                            alt="Profile"
                            className="w-32 h-32 rounded-full object-cover"
                        />
                    ) : (
                        <div className="w-32 h-32 bg-gray-600 rounded-full flex items-center justify-center text-4xl">
                            {currentUser?.username.charAt(0).toUpperCase()}
                        </div>
                    )}
                    <input
                        type="file"
                        accept="image/png"
                        className="absolute inset-0 opacity-0 cursor-pointer"
                        onChange={handleProfileImageChange}
                    />
                </div>
                <div className="w-full">
                    <label className="block text-sm text-gray-400">Display Name</label>
                    <input
                        type="text"
                        value={displayName}
                        onChange={(e) => setDisplayName(e.target.value)}
                        className="w-full p-2 mt-1 bg-gray-700 text-white rounded"
                    />
                </div>
                <div className="flex space-x-4">
                    <button
                        onClick={handleSave}
                        className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                    >
                        Save
                    </button>
                    <button
                        onClick={() => setOpen(false)}
                        className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </ManageModal>
    );
}

export default ManageCurrentUserModal;
