import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './views/Login';
import React from 'react';
import Chat from './views/Chat';
import RedirectToLanding from './views/RedirectToLanding';

function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route path="/:slug" element={<Login />} />
          <Route path="/chat" element={<Chat />} />
          <Route path='/' element={<RedirectToLanding />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
