export const fetchUnreadChannelsData = async (token) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user-channel-view/unread`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (response.ok) {
            return await response.json();
        } else {
            console.error('Error:', response);
        }
    } catch (error) {
        console.error('Error:', error);
    }
};

export const updateReadChannelData = async (token, selectedChannel) => {
    try {
        await fetch(`${process.env.REACT_APP_BACKEND_URL}/user-channel-view/`, {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                recipient_type: selectedChannel[0],
                reference_id: selectedChannel[1],
            }),
        });
    } catch (error) {
        console.error('Error:', error);
    }
};

export const fetchPresencesData = async (token) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/presence/`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (response.ok) {
            return await response.json();
        } else {
            console.error('Error:', response);
        }
    } catch (error) {
        console.error('Error:', error);
    }
};

export const fetchUsersData = async (token) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user/?page=1&per_page=100`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (response.ok) {
            return await response.json();
        } else {
            console.error('Error:', response);
        }
    } catch (error) {
        console.error('Error:', error);
    }
};

export const fetchCurrentUserData = async (token) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user/current`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (response.ok) {
            return await response.json();
        } else {
            console.error('Error:', response);
        }
    } catch (error) {
        console.error('Error:', error);
    }
};

export const fetchRolesData = async (token) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/role/`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (response.ok) {
            return await response.json();
        } else {
            console.error('Error:', response);
        }
    } catch (error) {
        console.error('Error:', error);
    }
};

export const fetchCurrentUserRolesData = async (token, currentUserId) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/role/?user_id=${currentUserId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (response.ok) {
            return await response.json();
        } else {
            console.error('Error:', response);
        }
    } catch (error) {
        console.error('Error:', error);
    }
};

export const fetchMessagesData = async (token, selectedChannel) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/message/?recipient_type=${selectedChannel[0]}&reference_id=${selectedChannel[1]}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        });

        if (response.ok) {
            return await response.json();
        } else {
            console.error('Error fetching messages:', response.statusText);
            return [];
        }
    } catch (error) {
        console.error('Error:', error);
        return [];
    }
};

export const fetchMediasData = async (token, messages) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/media/list`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ids: messages.map((message) => message.id),
            }),
        });
        if (response.ok) {
            return await response.json();
        } else {
            console.error('Error:', response);
        }
    } catch (error) {
        console.error('Error:', error);
    }
};

export const getChannelsData = async (token) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/channel/`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (response.ok) {
            return await response.json();
        } else {
            console.error('Error:', response);
        }
    } catch (error) {
        console.error('Error:', error);
    }
};

export const sendTypingIndicatorData = async (token, selectedChannel) => {
    try {
        const body = {
            recipient_type: selectedChannel[0],
            reference_id: selectedChannel[1],
        };

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/presence/typing`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (!response.ok) {
            console.error('Failed to send typing indicator:', response);
        }
    } catch (error) {
        console.error('Error sending typing indicator:', error);
    }
};

export const deleteMessageData = async (token, messageId) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/message/`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: messageId,
            }),
        });

        if (response.ok) {
            return await response.json();
        } else {
            console.error('Error:', response);
        }
    } catch (error) {
        console.error('Error:', error);
    }
};

export const editMessageData = async (token, messageId, content) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/message/`, {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: messageId,
                content: content,
            }),
        });

        if (response.ok) {
            return await response.json();
        } else {
            console.error('Error:', response);
        }
    } catch (error) {
        console.error('Error:', error);
    }
};

export const sendMessageData = async (token, selectedChannel, messageInput, uploadMediaList) => {
    try {
        const media_ids = uploadMediaList.map((media) => media.id);
        await fetch(`${process.env.REACT_APP_BACKEND_URL}/message/`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                message_type: "MESSAGE",
                media_ids: media_ids,
                recipient_type: selectedChannel[0],
                reference_id: selectedChannel[1],
                content: messageInput,
            }),
        });
    } catch (error) {
        console.error('Error:', error);
    }
};

export const fetchChannelRoleAccessesData = async (token, channelId) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/channel-role-access/?channel_id=${channelId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (response.ok) {
            const json = await response.json();
            return json;
        } else {
            console.error('Error:', response);
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

export const saveChannelRoleAccessData = async (token, channelId, selectedRole) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/channel-role-access/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ channel_id: channelId, role_id: selectedRole.id }),
        });
        if (response.ok) {
            return await response.json();
        } else {
            console.error('Error:', response);
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

export const deleteChannelRoleAccessData = async (token, selectedAccess) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/channel-role-access/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ id: selectedAccess.id }),
        });
        if (response.ok) {
            return await response.json();
        } else {
            console.error('Error:', response);
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

export const updateChannelData = async (token, selectedChannel, formValues) => {
    try {
        await fetch(`${process.env.REACT_APP_BACKEND_URL}/channel/`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ id: selectedChannel.id, ...formValues }),
        });
    } catch (error) {
        console.error('Error:', error);
    }
}

export const createChannelData = async (token, formValues) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/channel/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ ...formValues }),
        });
        if (response.ok) {
            return await response.json();
        } else {
            console.error('Error:', response);
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

export const deleteChannelData = async (token, selectedChannel) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/channel/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ id: selectedChannel.id }),
        });
        if (response.ok) {
            return await response.json();
        } else {
            console.error('Error:', response);
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

export const updateDisplayNameData = async (token, currentUser, displayName) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user/name`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ id: currentUser.id, display_name: displayName }),
        });

        if (response.ok) {
            return await response.json();
        } else {
            console.error('Error:', response);
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

export const uploadProfileImageData = async (token, file) => {
    try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user/profile-image`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: formData,
        });

        if (response.ok) {
            console.log("Profile image updated successfully.");
        } else {
            console.error('Error:', response);
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

export const updateRoleData = async (token, selectedRole, formValues) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/role/`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ id: selectedRole.id, ...formValues }),
        });
        if (response.ok) {
            return await response.json();
        } else {
            console.error('Error:', response);
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

export const createRoleData = async (token, formValues) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/role/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ ...formValues }),
        });
        if (response.ok) {
            return await response.json();
        } else {
            console.error('Error:', response);
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

export const deleteRoleData = async (token, selectedRole) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/role/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ id: selectedRole.id }),
        });
        if (response.ok) {
            return await response.json();
        } else {
            console.error('Error:', response);
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

export const fetchUserRoleAccessData = async (token, userId) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user-role-access/?user_id=${userId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (response.ok) {
            return await response.json();
        } else {
            console.error('Error:', response);
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

export const saveUserRoleAccessData = async (token, userId, selectedRole) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user-role-access/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ user_id: userId, role_id: selectedRole.id }),
        });
        if (response.ok) {
            return await response.json();
        } else {
            console.error('Error:', response);
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

export const deleteUserRoleAccessData = async (token, selectedAccess) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user-role-access/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ id: selectedAccess.id }),
        });
        if (response.ok) {
            return await response.json();
        } else {
            console.error('Error:', response);
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

export const markMessagesAsReadData = (token, messageIds) => {
    try {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/seen/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ ids: messageIds }),
        });
    } catch (error) {
        console.error('Error:', error);
    }
}

export const getSeenUserIdsData = async (token, messageId) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/seen/?message_id=${messageId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (response.ok) {
            return await response.json();
        } else {
            console.error('Failed to fetch seen users:', response);
        }
    } catch (error) {
        console.error('Error:', error);
    }
}