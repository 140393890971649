import { useEffect } from "react";

function RedirectToLanding() {
  useEffect(() => {
    window.location.assign(process.env.REACT_APP_LANDING_URL);
  }, []);

  return null;
}

export default RedirectToLanding;
