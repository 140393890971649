import React, { useState, useEffect, useRef } from "react";
import { Icon } from '@iconify/react';

function ChatInput({ messageInput, setMessageInput, handleKeyDown, sendMessage, uploadMediaList, setUploadMediaList, sendTypingIndicator, users, typingUserIds, selectedChannel }) {
    const typingTimeoutRef = useRef(null);
    const [canSendIndicator, setCanSendIndicator] = useState(true);

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        const token = localStorage.getItem('token');
        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/media/`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    method: 'POST',
                    body: formData,
                });

                if (response.ok) {
                    const result = await response.json();
                    setUploadMediaList(prevList => [...prevList, result]);
                } else {
                    console.error('File upload failed:', response);
                }
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    };

    const handleInputChange = (e) => {
        setMessageInput(e.target.value);

        if (canSendIndicator && e.target.value.trim() !== '') {
            sendTypingIndicator();
            setCanSendIndicator(false);
        }
    };

    useEffect(() => {
        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }

        typingTimeoutRef.current = setTimeout(() => {
            setCanSendIndicator(true);
        }, 1000);

        return () => {
            clearTimeout(typingTimeoutRef.current);
        }
    }, [canSendIndicator]);

    return (
        <div className="p-4 bg-gray-800 flex flex-col">
            <div className="flex flex-row">
                <input
                    disabled={selectedChannel[0] === null || selectedChannel[1] === null}
                    type="text"
                    value={messageInput}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Type a message..."
                    className="w-full p-3 text-white bg-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
                <button
                    className="text-left px-3 ml-2 py-2 text-sm font-medium text-gray-300 bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
                    onClick={() => document.getElementById('fileInput').click()}
                >
                    <Icon icon="ph:paperclip" className="w-6 h-6" />
                </button>
                <input
                    type="file"
                    id="fileInput"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />
                <button
                    onClick={sendMessage}
                    className="text-left px-3 ml-2 py-2 text-sm font-medium text-gray-300 bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
                >
                    <Icon icon="akar-icons:send" className="w-6 h-6" />
                </button>
            </div>
            {uploadMediaList.length > 0 && (
                <div className="text-white">
                    <p>Uploaded files:</p>
                    <ul>
                        {uploadMediaList.map((media) => (
                            <li key={media.id}>
                                <a href={media.url} target="_blank" rel="noopener noreferrer">{media.file_name}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            {typingUserIds.length > 0 && (
                <div className="text-white">
                    <p>
                        Typing: {typingUserIds
                            .map((userId) => users.find((user) => user.id === userId)?.display_name)
                            .filter((username) => username)
                            .join(', ')
                        }
                    </p>
                </div>
            )}

        </div>
    );
}

export default ChatInput;
