import { Icon } from "@iconify/react/dist/iconify.js"

function Avatar({className, profileImage = null}) {
    return (
        <div className={`flex-shrink-0 bg-gray-700 rounded-full ${className}`}>
            {profileImage ? (
                <img src={profileImage
                } alt="Profile" className={`rounded-full object-cover ${className}`} />
            ) : (
                <Icon icon="material-symbols:person" className={`text-gray-300 ${className}`} />
            )}
        </div>
    )

}

export default Avatar