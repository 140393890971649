import { useEffect, useState } from "react";
import ManageModal from "./ManageModal";
import { createRoleData, deleteRoleData, updateRoleData } from "../services/ChatService";

function ManageRolesModal({ setOpen, isOpen, roles, fetchRoles }) {
    const [selectedRole, setSelectedRole] = useState(null);
    const [isNewRole, setIsNewRole] = useState(false);
    const [formValues, setFormValues] = useState({
        name: '',
        administrator: false,
        manage_users: false,
        manage_channels: false,
        manage_roles: false,
    });

    const token = localStorage.getItem('token');

    useEffect(() => {
        setSelectedRole(null);
        setIsNewRole(false);
        setFormValues({
            name: '',
            administrator: false,
            manage_users: false,
            manage_channels: false,
            manage_roles: false,
        });
    }, [isOpen]);

    const handleRoleClick = (role) => {
        setIsNewRole(false);
        setSelectedRole(role);
        setFormValues({
            name: role.name,
            administrator: role.administrator,
            manage_users: role.manage_users,
            manage_channels: role.manage_channels,
            manage_roles: role.manage_roles,
        });
    };

    const handleAddNewRole = () => {
        setIsNewRole(true);
        setSelectedRole(null);
        setFormValues({
            name: '',
            administrator: false,
            manage_users: false,
            manage_channels: false,
            manage_roles: false,
        });
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const updateRole = async () => {
        updateRoleData(token, selectedRole, formValues).then(() => {
            fetchRoles();
            setOpen(false);
        });
    };

    const createRole = async () => {
        createRoleData(token, formValues).then(() => {
            fetchRoles();
            setOpen(false);
        });
    };

    const deleteRole = async () => {
        deleteRoleData(token, selectedRole).then(() => {
            fetchRoles();
            setOpen(false);
        });
    };

    const handleSave = () => {
        if (isNewRole) {
            createRole();
        } else {
            updateRole();
        }
    };

    return (
        <ManageModal setOpen={setOpen} isOpen={isOpen}>
            <div className="flex h-[400px]">
                {/* Role Selector */}
                <div className="flex flex-col bg-gray-800">
                    <div className="space-y-2 p-2 overflow-y-auto">
                        {roles.map((role) => (
                            <button
                                key={role.id}
                                onClick={() => handleRoleClick(role)}
                                className="cursor-pointer w-full text-left px-3 py-2 text-sm font-medium text-gray-300 bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
                            >
                                <div className="font-semibold">
                                    {role.name}
                                </div>
                            </button>
                        ))}
                    </div>
                    <div className="px-2 mt-auto mb-2">
                        <button
                            onClick={handleAddNewRole}
                            className="w-full cursor-pointer text-center p-2 text-sm font-medium text-gray-300 bg-gray-900 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
                        >
                            Add new role
                        </button>
                    </div>
                </div>

                {/* Role Details Form */}
                <div className="flex flex-col w-[200px] sm:w-[400px] p-2 bg-gray-900">
                    {(selectedRole || isNewRole) && (
                        <>
                            <form className="space-y-2">
                                <div>
                                    <label className="block text-gray-300">Role Name</label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={formValues.name}
                                        onChange={handleChange}
                                        className="w-full p-2 text-gray-900 rounded-md"
                                    />
                                </div>
                                <div className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        name="administrator"
                                        checked={formValues.administrator}
                                        onChange={handleChange}
                                        className="form-checkbox"
                                    />
                                    <label className="text-gray-300">Administrator</label>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        name="manage_users"
                                        checked={formValues.manage_users}
                                        onChange={handleChange}
                                        className="form-checkbox"
                                    />
                                    <label className="text-gray-300">Manage Users</label>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        name="manage_channels"
                                        checked={formValues.manage_channels}
                                        onChange={handleChange}
                                        className="form-checkbox"
                                    />
                                    <label className="text-gray-300">Manage Channels</label>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        name="manage_roles"
                                        checked={formValues.manage_roles}
                                        onChange={handleChange}
                                        className="form-checkbox"
                                    />
                                    <label className="text-gray-300">Manage Roles</label>
                                </div>
                            </form>
                            <div className="flex mx-auto h-full items-center justify-center space-x-16">
                                {!isNewRole && (
                                    <button
                                        onClick={deleteRole}
                                        className="flex justify-center items-center cursor-pointer text-left mt-auto h-[36px] w-[64px] text-sm text-sm font-bold text-gray-300 bg-[#513737] rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
                                    >
                                        Delete
                                    </button>
                                )}
                                <button
                                    onClick={handleSave}
                                    className="flex justify-center items-center cursor-pointer text-left mt-auto h-[36px] w-[64px] text-sm text-sm font-bold text-gray-300 bg-[#375137] rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
                                >
                                    Save
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </ManageModal>
    );
}

export default ManageRolesModal;
