import React, { useState } from "react";
import ManageRolesModal from "./ManageRolesModal";
import ManageChannelsModal from "./ManageChannelsModal";

function ChatHeader({ channels, users, roles, selectedChannel, permissions, fetchRoles, fetchChannels }) {
    const [manageRolesOpen, setManageRolesOpen] = useState(false);
    const [manageChannelsOpen, setManageChannelsOpen] = useState(false);

    // Get the selected channel or user based on the selectedChannel prop
    const selectedChannelObject = selectedChannel[0] === "CHANNEL"
        ? channels.find((channel) => channel.id === selectedChannel[1])
        : null;

    const selectedUser = selectedChannel[0] === "USER"
        ? users.find((user) => user.id === selectedChannel[1])
        : null;

    return (
        <div className="flex items-center justify-between p-4 bg-gray-800 text-white shadow">
            <div className="flex items-center space-x-4 flex-row">
                <h1 className="text-lg font-semibold">
                    {selectedChannelObject ? selectedChannelObject.name : selectedUser?.display_name}
                </h1>
                {selectedChannelObject && (
                    <p className="hidden sm:block sm:text-xs text-gray-400">
                        {selectedChannelObject.description}
                    </p>
                )}
            </div>
            <div className="flex space-x-2 flex-row text-sm">
                {(permissions.administrator || permissions.manage_roles) && (
                    <p onClick={() => setManageRolesOpen(true)} className="text-sm cursor-pointer">Manage Roles</p>
                )}
                {(permissions.administrator || permissions.manage_channels) && (
                    <p onClick={() => setManageChannelsOpen(true)} className="text-sm cursor-pointer">Manage Channels</p>
                )}
            </div>
            <ManageRolesModal setOpen={setManageRolesOpen} isOpen={manageRolesOpen} roles={roles} fetchRoles={fetchRoles} />
            <ManageChannelsModal setOpen={setManageChannelsOpen} isOpen={manageChannelsOpen} channels={channels} fetchChannels={fetchChannels} />
        </div>
    );
}

export default ChatHeader;
