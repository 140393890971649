import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import ChatHeader from "../components/ChatHeader";
import MessageList from "../components/MessageList";
import ChatInput from "../components/ChatInput";
import { WillAddToRightChannel } from "../utils/Messages";
import { deleteMessageData, editMessageData, fetchCurrentUserData, fetchCurrentUserRolesData, fetchMediasData, fetchMessagesData, fetchPresencesData, fetchRolesData, fetchUnreadChannelsData, fetchUsersData, getChannelsData, sendMessageData, sendTypingIndicatorData, updateReadChannelData } from "../services/ChatService";
import usePermissions from "../hooks/usePermissions";

function Chat() {
    const navigate = useNavigate();
    const [selectedChannel, setSelectedChannel] = useState([null, null]);
    const selectedChannelRef = useRef(selectedChannel);
    const [channels, setChannels] = useState([]);
    const [messages, setMessages] = useState([]);
    const [roles, setRoles] = useState([]);
    const [messageInput, setMessageInput] = useState('');
    const [users, setUsers] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const currentUserRef = useRef(currentUser);
    const [currentUserRoles, setCurrentUserRoles] = useState([]);
    const [messageCategory, setMessageCategory] = useState(1);
    const [uploadMediaList, setUploadMediaList] = useState([]);
    const [mediaList, setMediaList] = useState([]);
    const [presences, setPresences] = useState([]);
    const [typingUserIds, setTypingUserIds] = useState([]);
    const [unreadChannels, setUnreadChannels] = useState([]);

    const typingTimeouts = useRef({});

    const permissions = usePermissions(currentUserRoles);

    const token = localStorage.getItem('token');

    const fetchUnreadChannels = async () => {
        fetchUnreadChannelsData(token).then((data) => {
            setUnreadChannels(data);
        });
    }

    const updateReadChannel = async () => {
        if(selectedChannelRef.current[0] === null || selectedChannelRef.current[1] === null) {
            return;
        }
        updateReadChannelData(token, selectedChannelRef.current).then(() => {
            fetchUnreadChannels();
        });
    }

    const fetchPresences = async () => {
        fetchPresencesData(token).then((data) => {
            setPresences(data);
        });
    }

    const fetchUsers = async () => {
        fetchUsersData(token).then((data) => {
            setUsers(data);
        });
    }

    const fetchCurrentUser = async () => {
        fetchCurrentUserData(token).then((data) => {
            setCurrentUser(data);
        });
    }

    const fetchRoles = async () => {
        fetchRolesData(token).then((data) => {
            setRoles(data);
        });
    }

    const fetchCurrentUserRoles = async () => {
        fetchCurrentUserRolesData(token, currentUser.id).then((data) => {
            setCurrentUserRoles(data);
        });
    }

    const fetchMessages = async () => {
        if(selectedChannelRef.current[0] === null || selectedChannelRef.current[1] === null) {
            return;
        }
        fetchMessagesData(token, selectedChannelRef.current).then((data) => {
            setMessages(data);
        });
    }

    const fetchMedias = async (messages) => {
        fetchMediasData(token, messages).then((data) => {
            setMediaList(data);
        });
    }

    const getChannels = async () => {
        getChannelsData(token).then((data) => {
            setChannels(data);
            if ((Array.isArray(data) && data.length > 0) && (selectedChannel[0] === null || selectedChannel[1] === null)) {
                setSelectedChannel(["CHANNEL", data[0].id]);
            }
        });
    }

    const sendTypingIndicator = async () => {
        sendTypingIndicatorData(token, selectedChannelRef.current).then(() => {
            console.log('Typing indicator sent.');
        });
    }

    const deleteMessage = async (messageId) => {
        deleteMessageData(token, messageId).then(() => {
            console.log('Message deleted.');
        });
    }

    const editMessage = async (messageId, content) => {
        editMessageData(token, messageId, content).then(() => {
            console.log('Message edited.');
        });
    }

    const sendMessage = async () => {
        sendMessageData(token, selectedChannelRef.current, messageInput, uploadMediaList).then(() => {
            setMessageInput('');
            setUploadMediaList([]);
        });
    };

    const handleTypingIndicator = (userId) => {
        if (typingTimeouts.current[userId]) {
            clearTimeout(typingTimeouts.current[userId]);
        }

        typingTimeouts.current[userId] = setTimeout(() => {
            setTypingUserIds((typingUserIds) => {
                return typingUserIds.filter((id) => id !== userId);
            });
            delete typingTimeouts.current[userId];
        }, 2000);
    };

    useEffect(() => {
        let ws;
        let reconnectTimeout;
    
        const establishWebSocketConnection = () => {
            ws = new WebSocket(`${process.env.REACT_APP_BACKEND_URL_WS}/chat/ws?token=${encodeURIComponent(token)}`);
    
            ws.onopen = () => {
                console.log('WebSocket connection established.');
                clearTimeout(reconnectTimeout);
            };
    
            ws.onmessage = (event) => {
                console.log('WebSocket message received:', event.data);
                const json = JSON.parse(event.data);
                console.log("current channel id: ", selectedChannelRef.current[1], "message channel id: ", json.reference_id);
                if (json.message_type === 'MESSAGE') {
                    updateReadChannel();
                    if (WillAddToRightChannel(selectedChannelRef, json)) {
                        setMessages((messages) => [json, ...messages]);
                    }
                }
                if (json.message_type === "EDIT_MESSAGE") {
                    console.log('Message:', json.content);
                    setMessages((messages) => {
                        return messages.map((message) => (message.id === json.id ? json : message));
                    });
                }
                if (json.message_type === "DELETE_MESSAGE") {
                    console.log('Message:', json.content);
                    setMessages((messages) => messages.filter((message) => message.id !== json.id));
                }
                if (json.message_type === "UPDATE_STATUS") {
                    fetchRoles();
                    fetchUsers();
                    getChannels();
                    fetchCurrentUser();
                    fetchPresences();
                }
                if (json.message_type === "UPDATE_USERS") {
                    fetchUsers();
                    fetchPresences();
                }
                if (json.message_type === "TYPING" && WillAddToRightChannel(selectedChannelRef, json) && json.user_id !== currentUserRef.current?.id) {
                    setTypingUserIds((typingUserIds) => {
                        if (!typingUserIds.includes(json.user_id)) {
                            return [...typingUserIds, json.user_id];
                        }
                        return typingUserIds;
                    });
                    handleTypingIndicator(json.user_id);
                }
            };
    
            ws.onclose = () => {
                console.log('WebSocket connection closed. Attempting to reconnect...');
                reconnect();
            };
    
            ws.onerror = (error) => {
                console.error('WebSocket error:', error);
                ws.close();
            };
        };
    
        const reconnect = () => {
            reconnectTimeout = setTimeout(() => {
                console.log('Reconnecting to WebSocket...');
                establishWebSocketConnection();
            }, 5000);
        };
    
        establishWebSocketConnection();
    
        return () => {
            if (ws) {
                ws.close();
            }
            clearTimeout(reconnectTimeout);
        };
    }, [token, selectedChannelRef, currentUserRef]);
    

    useEffect(() => {
        selectedChannelRef.current = selectedChannel; // Keep ref updated with selectedChannel state
        setTypingUserIds([]);
        if (selectedChannel[0] && selectedChannel[1]) {
            updateReadChannel();
        }
    }, [selectedChannel]);

    useEffect(() => {
        if (currentUser) {
            currentUserRef.current = currentUser;
            fetchCurrentUserRoles();
        }
    }, [currentUser]);

    useEffect(() => {
        fetchRoles();
        fetchUsers();
        fetchCurrentUser();
        fetchPresences();
    }, []);

    useEffect(() => {
        if (currentUser) {
            fetchCurrentUserRoles();
        }
    }, [currentUser]);

    useEffect(() => {
        if (!token) {
            navigate('/');
        }
        fetchMessages();
        getChannels();
    }, [selectedChannel]);

    useEffect(() => {
        fetchMedias(messages);
        fetchUnreadChannels();
    }, [messages]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            sendMessage();
        }
    };

    return (
        <div className="flex h-screen">
            <Sidebar
                channels={channels}
                users={users}
                roles={roles}
                setSelectedChannel={setSelectedChannel}
                currentUser={currentUser}
                messageCategory={messageCategory}
                setMessageCategory={setMessageCategory}
                permissions={permissions}
                presences={presences}
                unreadChannels={unreadChannels}
                fetchCurrentUser={fetchCurrentUser}
            />
            <div className="flex flex-col flex-1">
                <ChatHeader
                    channels={channels}
                    users={users}
                    roles={roles}
                    selectedChannel={selectedChannel}
                    permissions={permissions}
                    fetchRoles={fetchRoles}
                    fetchChannels={getChannels}
                />
                <MessageList
                    messages={messages}
                    users={users}
                    mediaList={mediaList}
                    deleteMessage={deleteMessage}
                    editMessage={editMessage}
                    permissions={permissions}
                    currentUser={currentUser}
                />
                <ChatInput
                    messageInput={messageInput}
                    setMessageInput={setMessageInput}
                    handleKeyDown={handleKeyDown}
                    sendMessage={sendMessage}
                    uploadMediaList={uploadMediaList}
                    setUploadMediaList={setUploadMediaList}
                    sendTypingIndicator={sendTypingIndicator}
                    users={users}
                    typingUserIds={typingUserIds}
                    selectedChannel={selectedChannel}
                />
            </div>
        </div>
    );
}

export default Chat;
