import { useEffect, useState } from "react";
import { getPermissionsFromRoles } from "../utils/Permissions";

const usePermissions = (currentUserRoles) => {
    const [permissions, setPermissions] = useState({
        administrator: false,
        manage_users: false,
        manage_channels: false,
        manage_roles: false
    });

    useEffect(() => {
        const newPermissions = getPermissionsFromRoles(currentUserRoles);
        setPermissions(newPermissions);
    }, [currentUserRoles]);

    return permissions;
};

export default usePermissions;
