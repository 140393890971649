import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useState, useRef, useEffect } from "react";
import ManageChannelRoleAccessModal from "./ManageChannelRoleAccessModal";
import ManageUserRoleAccessModal from "./ManageUserRoleAccessModal";
import ManageCurrentUserModal from "./ManageCurrentUserModal";
import Avatar from "./Avatar";

function Sidebar({
    channels,
    users,
    roles,
    setSelectedChannel,
    currentUser,
    messageCategory,
    setMessageCategory,
    permissions,
    presences,
    unreadChannels,
    fetchCurrentUser
}) {
    const [manageChannelRoleAccessOpen, setManageChannelRoleAccessOpen] = useState(false);
    const [manageUserRoleAccessOpen, setUserRoleAccessOpen] = useState(false);
    const [manageCurrentUserOpen, setManageCurrentUserOpen] = useState(false);
    const [selectManageChannel, setSelectManageChannel] = useState(0);
    const [selectManageUser, setSelectManageUser] = useState(0);
    const [sidebarWidth, setSidebarWidth] = useState(256);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State to toggle sidebar visibility

    const sidebarRef = useRef(null);
    const isDraggingRef = useRef(false);

    const handleMouseDown = () => {
        isDraggingRef.current = true;
    };

    const handleMouseMove = (e) => {
        if (isDraggingRef.current) {
            const newWidth = e.clientX - sidebarRef.current.getBoundingClientRect().left;
            setSidebarWidth(newWidth >= 200 ? newWidth : 200);
        }
    };

    const handleMouseUp = () => {
        isDraggingRef.current = false;
    };

    const handleChannelRoleAccessClick = (channelId) => {
        setSelectManageChannel(channelId);
        setManageChannelRoleAccessOpen(true);
    };

    const handleUserRoleAccessClick = (userId) => {
        setSelectManageUser(userId);
        setUserRoleAccessOpen(true);
    };

    const isUnread = (recipient_type, reference_id) => {
        return unreadChannels.some(
            (unreadChannel) =>
                unreadChannel.recipient_type === recipient_type && unreadChannel.reference_id === reference_id
        );
    };

    const handleSelectChannel = (data) => {
        setSelectedChannel(data);
        setIsSidebarOpen(false);
    }

    useEffect(() => {
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
        };
    }, []);

    return (
        <>
            <div
                ref={sidebarRef}
                style={{ width: sidebarWidth }}
                className={`fixed z-[200] top-0 left-0 bg-gray-800 h-full flex flex-row transform transition-transform duration-300 ease-in-out ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"
                    } lg:static lg:translate-x-0`}
            >
                <button
                    onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                    className={`absolute opacity-80 top-10 right-2 mr-[-55px] z-50 p-2 bg-gray-700 text-white rounded-full lg:hidden`}
                >
                    <Icon icon={isSidebarOpen ? "material-symbols:close-fullscreen" : "mdi-menu"} className="text-xl" />
                </button>
                <div className="flex flex-col p-4 w-full">
                    <div className="flex items-center pb-2">
                        {messageCategory === 1 ? (
                            <>
                                <h2 className="text-xl font-semibold text-white truncate">Channels</h2>
                                <button
                                    onClick={() => setMessageCategory(2)}
                                    className="flex justify-center items-center ml-auto w-[36px] h-[36px] text-center px-2 py-2 text-sm font-medium text-gray-300 bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
                                >
                                    <Icon icon="material-symbols:person" className="text-3xl" />
                                </button>
                            </>
                        ) : (
                            <>
                                <h2 className="text-xl font-semibold text-white truncate">Users</h2>
                                <button
                                    onClick={() => setMessageCategory(1)}
                                    className="flex justify-center items-center ml-auto w-[36px] h-[36px] text-center px-2 py-2 text-sm font-medium text-gray-300 bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
                                >
                                    <Icon icon="fluent:channel-16-filled" className="text-3xl" />
                                </button>
                            </>
                        )}
                    </div>

                    <div className="flex-1 overflow-y-auto">
                        <div className="space-y-2">
                            {messageCategory === 1 ? (
                                channels.map((channel) => (
                                    <div key={channel.id} className="flex flex-row items-center">
                                        <button
                                            onClick={() => handleSelectChannel(["CHANNEL", channel.id])}
                                            className="w-full text-left px-3 py-2 text-sm font-medium text-gray-300 bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600 truncate"
                                        >
                                            <span className={`${isUnread("CHANNEL", channel.id) ? 'font-extrabold' : 'font-normal'}`}>{channel.name}</span>
                                        </button>
                                        {permissions?.manage_channels && (
                                            <button onClick={() => handleChannelRoleAccessClick(channel.id)} className="pl-1">
                                                <Icon icon="bx:edit" className="text-xl text-gray-300" />
                                            </button>
                                        )}
                                    </div>
                                ))
                            ) : (
                                users.filter(user => user.id !== currentUser?.id).map((user) => (
                                    <div key={user.id} className="flex flex-row items-center">
                                        <button
                                            onClick={() => handleSelectChannel(["USER", user.id])}
                                            className="flex items-center w-full text-left px-2 py-2 text-sm font-medium text-gray-300 bg-gray-700 ring-gray-700 hover:ring-gray-600 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600 truncate"
                                        >
                                            <div className="relative mr-1 flex-shrink-0 w-5 h-5 rounded-md ring-inherit">
                                                <Avatar className="w-5 h-5 text-gray-300" profileImage={user?.profile_image} />
                                                {presences?.includes(user.id) && (
                                                    <div className="ring-2 ring-inherit absolute bottom-0 right-0 w-1.5 h-1.5 bg-green-500 rounded-full" />
                                                )}
                                            </div>
                                            <span className={`truncate ${isUnread("USER", user.id) ? 'font-extrabold' : 'font-normal'}`}>{user.display_name}</span>
                                        </button>
                                        {permissions?.manage_roles && (
                                            <button onClick={() => handleUserRoleAccessClick(user.id)} className="pl-1">
                                                <Icon icon="bx:edit" className="text-xl text-gray-300" />
                                            </button>
                                        )}
                                    </div>
                                ))
                            )}
                        </div>
                    </div>

                    <div className="flex space-x-3 items-center pt-4">
                        <Avatar className="w-10 h-10 bg-gray-700 rounded-xl" profileImage={currentUser?.profile_image} />
                        <div className="text-sm font-semibold text-white truncate">
                            {currentUser?.display_name}
                        </div>
                        <button onClick={() => setManageCurrentUserOpen(true)}>
                            <Icon icon="material-symbols:edit" className="w-5 h-5 text-gray-300" />
                        </button>
                    </div>
                </div>

                <div
                    onMouseDown={handleMouseDown}
                    className="w-2 h-full opacity-10 bg-gray-600 cursor-ew-resize absolute right-0 top-0"
                    style={{ left: sidebarWidth - 8 }}
                />

                <ManageChannelRoleAccessModal
                    isOpen={manageChannelRoleAccessOpen}
                    setOpen={setManageChannelRoleAccessOpen}
                    channelId={selectManageChannel}
                    roles={roles}
                />
                <ManageUserRoleAccessModal
                    isOpen={manageUserRoleAccessOpen}
                    setOpen={setUserRoleAccessOpen}
                    userId={selectManageUser}
                    roles={roles}
                />
                <ManageCurrentUserModal
                    isOpen={manageCurrentUserOpen}
                    setOpen={setManageCurrentUserOpen}
                    currentUser={currentUser}
                    fetchCurrentUser={fetchCurrentUser}
                />
            </div>
        </>
    );
}

export default Sidebar;
