import { useEffect, useState } from "react";
import ManageModal from "./ManageModal";
import { deleteUserRoleAccessData, fetchUserRoleAccessData, saveUserRoleAccessData } from "../services/ChatService";

function ManageUserRoleAccessModal({ setOpen, isOpen, roles, userId }) {
    const [userRoleAccesses, setUserRoleAccesses] = useState([]);
    const [selectedRole, setSelectedRole] = useState(null);
    const [selectedAccess, setSelectedAccess] = useState(null);
    const [isNewUserRoleAccess, setIsNewUserRoleAccess] = useState(false);
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (isOpen && userId > 0) {
            fetchUserRoleAccessData(token, userId).then((json) => setUserRoleAccesses(json));
        } else {
            setUserRoleAccesses([]);
            setSelectedAccess(null);
            setIsNewUserRoleAccess(false);
        }
    }, [isOpen, userId]);

    const handleSave = async () => {
        saveUserRoleAccessData(token, userId, selectedRole).then(() => {setOpen(false)});
    }

    const handleDelete = async () => {
        deleteUserRoleAccessData(token, selectedAccess).then(() => {setOpen(false)});
    }

    return (
        <ManageModal setOpen={setOpen} isOpen={isOpen}>
            <div className="flex h-[400px]">
                {/* Channel Selector */}
                <div className="flex flex-col bg-gray-800">
                    <div className="space-y-2 p-2 overflow-y-auto">
                        {userRoleAccesses.map((access) => (
                            <button
                                key={access.id}
                                onClick={() => setSelectedAccess(access)}
                                className="cursor-pointer w-full text-left px-3 py-2 text-sm font-medium text-gray-300 bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
                            >
                                <div className="font-semibold">
                                    {roles.find((role) => role.id === access.role_id)?.name}
                                </div>
                            </button>
                        ))}
                    </div>
                    <div className="px-2 mt-auto mb-2">
                        <button
                            onClick={() => setIsNewUserRoleAccess(true)}
                            className="w-full cursor-pointer text-center p-2 text-sm font-medium text-gray-300 bg-gray-900 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
                        >
                            Add new user role access
                        </button>
                    </div>
                </div>

                {/* Channel Details Form */}
                <div className="flex flex-col w-[200px] sm:w-[400px] p-2 bg-gray-900">
                    {(selectedAccess || isNewUserRoleAccess) && (
                        <>
                            {isNewUserRoleAccess && (
                                <div className="flex flex-col space-y-2">
                                    <label className="text-sm font-semibold text-gray-300">Role</label>
                                    <select
                                        value={selectedRole?.id || ''}
                                        onChange={(e) => setSelectedRole(roles.find((role) => role.id === e.target.value))}
                                        className="w-full text-sm text-gray-300 bg-gray-700 rounded-md focus:outline-none focus:bg-gray-600"
                                    >
                                        <option value="" disabled>Select a role</option>
                                        {roles.filter((role) => !userRoleAccesses.find((access) => access.role_id === role.id)).map((role) => (
                                            <option key={role.id} value={role.id}>{role.name}</option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            <div className="flex mx-auto h-full items-center justify-center space-x-16">
                                {isNewUserRoleAccess ? (
                                    <button
                                        onClick={() => handleSave()}
                                        className="flex justify-center items-center cursor-pointer text-left mt-auto h-[36px] w-[64px] text-sm text-sm font-bold text-gray-300 bg-[#375137] rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
                                    >
                                        Save
                                    </button>
                                ) : (
                                    <button
                                        onClick={() => handleDelete()}
                                        className="flex justify-center items-center cursor-pointer text-left mt-auto h-[36px] w-[64px] text-sm text-sm font-bold text-gray-300 bg-[#513737] rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
                                    >
                                        Delete
                                    </button>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </ManageModal>
    );
}

export default ManageUserRoleAccessModal;
