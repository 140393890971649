export function getPermissionsFromRoles(roles) {
    const permissions = {
        administrator: false,
        manage_users: false,
        manage_channels: false,
        manage_roles: false
    };

    roles.forEach(role => {
        if (role.administrator) {
            permissions.administrator = true;
            permissions.manage_users = true;
            permissions.manage_channels = true;
            permissions.manage_roles = true;
        }
        if (role.manage_users) {
            permissions.manage_users = true;
        }
        if (role.manage_channels) {
            permissions.manage_channels = true;
        }
        if (role.manage_roles) {
            permissions.manage_roles = true;
        }
    });

    return permissions;
}
