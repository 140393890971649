import { useEffect, useState } from "react";
import ManageModal from "./ManageModal";
import Avatar from "./Avatar";
import { getSeenUserIdsData } from "../services/ChatService";

function SeenModal({ setOpen, isOpen, messageId, users }) {
    const [seenUserIds, setSeenUserIds] = useState([]);
    const token = localStorage.getItem("token");

    const getSeenUserIds = async (messageId) => {
        getSeenUserIdsData(token, messageId).then((data) => {
            setSeenUserIds(data);
        });
    }

    useEffect(() => {
        if (isOpen && messageId) {
            getSeenUserIds(messageId);
        } else {
            setSeenUserIds([]);
        }
    }, [isOpen, messageId]);

    return (
        <ManageModal setOpen={setOpen} isOpen={isOpen}>
            <div className="flex h-[400px]">
                <div className="flex flex-col bg-gray-800">
                    <p className="text-white font-semibold mx-auto">Seen Users</p>
                    <div className="space-y-2 px-2 pb-2 overflow-y-auto">
                        {seenUserIds.map((seenUserId) => (
                            <div
                                key={seenUserId}
                                className="space-x-2 flex flex-row items-center w-full text-left px-3 py-2 text-sm font-medium text-gray-300 bg-gray-700 rounded-md focus:outline-none focus:bg-gray-600"
                            >
                                <Avatar className="w-5 h-5" profileImage={users.find((user) => user.id === seenUserId)?.profile_image} />
                                <div className="font-semibold">
                                    {users.find((user) => user.id === seenUserId)?.display_name}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </ManageModal>
    );
}

export default SeenModal;
