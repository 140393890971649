import React, { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import RedirectToLanding from './RedirectToLanding';

function Login() {
    const navigate = useNavigate();
    const { slug } = useParams();
    const urlRef = useRef(null);
    useEffect(() => {
        const getOrganizationExists = async () => {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/organization/?slug=${slug}`);
            if(!response.ok){
                window.location.assign(process.env.REACT_APP_LANDING_URL);
            }
        }
        if(slug){
            getOrganizationExists();
        }else{
            window.location.assign(process.env.REACT_APP_LANDING_URL);
        }
    }, [slug]);

    if(!slug || slug === undefined){
        return <RedirectToLanding />
    }

    const currentUrl = window.location.href;

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.target);
        const username = data.get('username');

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/secret`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Client-Secret': `${process.env.REACT_APP_CLIENT_SECRET}`,
            },
            body: JSON.stringify({ username: username, display_name: username, slug: slug }),
        });

        if (response.ok) {
            const json = await response.json();
            navigate('/chat');
            localStorage.setItem('token', json.token);
        } else {
            console.error('Error:', response);
        }
    }

    const handleMouseEnter = () => {
        if (urlRef.current) {
            urlRef.current.select();
        }
    }

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="w-full max-w-sm p-6 bg-white rounded-lg shadow-lg">
                <h1 className="text-center font-bold pb-4">CONVOFORGE DEMO</h1>
                <form className="flex flex-col space-y-4" onSubmit={handleSubmit}>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">
                            Username
                        </label>
                        <input
                            type="text"
                            name="username"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full py-2 px-4 bg-indigo-600 text-white rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    >
                        Login
                    </button>
                </form>
                <div className="space-y-2 pt-4">
                    <p>An administrator user has been created with the username <span className="font-bold">admin</span></p>
                    <p>To return to or share this chat, save the link:</p>
                    <input
                        type="text"
                        readOnly
                        value={currentUrl}
                        ref={urlRef}
                        onFocus={handleMouseEnter}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm cursor-pointer"
                    />
                </div>
            </div>
        </div>
    );
}

export default Login;
